@tailwind base;
@tailwind components;
@tailwind utilities;

/* html, body { height: 100%; overflow:auto; } */

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background-color: #595E69;
  max-width: 240px;
}

.leaflet-popup-tip-container {
  display: none !important;
}

.leaflet-popup-close-button {
  display: none !important;
}

@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}