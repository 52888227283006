.btn {
  @apply rounded text-sm;
}

.btn-primary {
  @apply bg-Blueberry-dark-default border border-Blueberry-dark-default text-white
    md:enabled:hover:bg-Blueberry-light-default 
    disabled:opacity-60;
}

.btn-secondary {
  @apply border border-Blueberry-dark-default bg-white text-Blueberry-dark-default
    md:hover:bg-Blueberry-light-secondary-hover
    disabled:bg-Grey-tint disabled:text-Grey-dark disabled:border-Grey-dark;
}

.btn-tertiary {
  @apply border border-white bg-transparent text-white
    md:hover:bg-Grey-tint md:hover:text-Grey-dark
    disabled:bg-Grey-tint disabled:text-Grey-default disabled:border-Grey-tint;
}

.btn-link {
  @apply bg-white border border-white text-Blueberry-dark-default
    md:hover:bg-Blueberry-light-link-hover border-Blueberry-light-link-hover
    disabled:bg-Grey-tint disabled:text-Grey-default disabled:border-Grey-tint;
}

.btn-large {
  @apply px-10 py-5;
}

.btn-medium {
  @apply px-8 py-4;
}

.btn-small {
  @apply px-4 py-2;
}
